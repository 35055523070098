<template>
  <div>
    <!-- Breadcrumb start -->
    <section class="bradcrumb_sec">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="bread_crumb_box mb_22">
              <h2 class="heading_2 font_bold mb_23">
                Ergo video <span class="text_primary">library</span>
              </h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link
                      class="navbar-brand m-0 p-0"
                      :to="{ name: 'user_home' }"
                    >
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Video Library
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb end -->
    <!-- text start -->
    <section class="video_sec mt_20">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-12 col-xl-9 mx-auto">
                <div class="image_text_right_side">
                  <h2 class="text_black mb_24 font_size_32">
                    Video Collections Created Just For You
                  </h2>
                  <p class="text_balticsea mb_74 font_size_22">
                    No matter where you are working these days, our robust video
                    library has something for everyone. Topics range from
                    practical ergo tips to everyday stretches and yoga exercises
                    to time management strategies and much much more. You will
                    learn something new and useful in every playlist in the
                    collection. These were made with you, your health and your
                    success in mind. Enjoy!
                  </p>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row">
              
              <div class="col-md-12 col-xl-9 mx-auto">
                <div class="row">
                  <div class="col-md-6" v-if="video_playlist.includes('Everyday Stretches')">
                    <div class="image_text_card">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Everyday Stretches
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e1.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!-- </router-link>  -->
                    </div>
                  </div>
                  <div class="col-md-6" v-if="video_playlist.includes('Ergo Essentials')">
                    <div class="image_text_card">
                      <!--<router-link :to="{ name: 'ergo_essentials' }"> -->
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Ergo Essentials
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e2.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!--</router-link>-->
                    </div>
                  </div>
                  <div class="col-md-6" v-if="video_playlist.includes('Healthy Living')">
                    <div class="image_text_card">
                      <!--<router-link :to="{ name: 'clean_eating' }"> -->
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Healthy Living
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e3.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!--</router-link>-->
                    </div>
                  </div>
                  <div class="col-md-6" v-if="video_playlist.includes('Yoga Exercise')">
                    <div class="image_text_card">
                      <!--<router-link :to="{ name: 'exercise' }"> -->
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal4"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Yoga Exercises
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e4.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!--</router-link>-->
                    </div>
                  </div>
                  <div class="col-md-6" v-if="video_playlist.includes('Productivity Hacks')">
                    <div class="image_text_card">
                      <!--<router-link :to="{ name: 'productivity_structure' }"> -->
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal5"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Productivity Hacks
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e5.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!--</router-link>-->
                    </div>
                  </div>
                  <div class="col-md-6" v-if="video_playlist.includes('Posture Perfect')">
                    <div class="image_text_card">
                      <!--<router-link :to="{ name: 'posture_perfect' }"> -->
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal6"
                      >
                        <div class="position-absolute image_text_box">
                          <h5 class="font_size_34 font_bold text_black">
                            Posture Perfect
                          </h5>
                        </div>
                        <img
                          src="../assets/images/video_library/e6.png"
                          alt="img"
                          class="custome_border border_black"
                        />
                      </a>
                      <!--</router-link>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- text end -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      @click.self="close"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed"
                src="https://vimeo.com/showcase/9372748/embed"
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                " mozallowfullscreen webkitallowfullscreen allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal2"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal1"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed1"
                src="https://vimeo.com/showcase/9372769/embed"
                allowfullscreen
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal3"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal2"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed2"
                src="https://vimeo.com/showcase/9372774/embed"
                allowfullscreen
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal4"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal3"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed3"
                src="https://vimeo.com/showcase/9372776/embed"
                allowfullscreen
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal5"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal4"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed4"
                src="https://vimeo.com/showcase/9372781/embed"
                allowfullscreen
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal6"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal5"
              >
                <img src="../assets/images/close_modal.png" />
              </a>
            </div>
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed5"
                src="https://vimeo.com/showcase/9372787/embed"
                allowfullscreen
                frameborder="0"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
  </div>
</template>
<script>
export default {
  name: "Ergo_video_library",
  data() {
    return {
      // video_playlist: ["Everyday Stretches","Ergo Essentials","Healthy Living","Yoga Exercise","Productivity Hacks","Posture Perfect"],
      video_playlist: [],
    };
  },
  beforeMount(){
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    let company_data = data.client;
    //This is used for menu access
    if (company_data.site_menu_details != undefined) {
      if(company_data.site_menu_details.Videos!= undefined && company_data.site_menu_details.Videos.status==0){
          this.$router.push({ name: "404" });
      }
    }
  },
  mounted() {
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    let company_data = data.client;
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === "undefined") {
      console.log("no company values home");
      this.video_playlist= ["Everyday Stretches","Ergo Essentials","Healthy Living","Yoga Exercise","Productivity Hacks","Posture Perfect"];
    } else {      
      this.video_playlist = company_data.video_playlist;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    hideModal() {
      // this.$emit('hide');
      document.getElementById("closed").src += "";
    },
    hideModal1() {
      // this.$emit('hide');
      document.getElementById("closed1").src += "";
    },
    hideModal2() {
      // this.$emit('hide');
      document.getElementById("closed2").src += "";
    },
    hideModal3() {
      // this.$emit('hide');
      document.getElementById("closed3").src += "";
    },
    hideModal4() {
      // this.$emit('hide');
      document.getElementById("closed4").src += "";
    },
    hideModal5() {
      // this.$emit('hide');
      document.getElementById("closed5").src += "";
    },
  },
};
</script>


